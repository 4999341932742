import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Post from '../components/Post';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
  data: MarkdownRemark
};

const PostTemplate = ({ data }: Props) => {
  const {
    url: siteUrl,
    title: siteTitle,
    subtitle: siteSubtitle,
    keywords: siteKeywords,
    author: siteAuthor,
    twitter: siteTwitter
  } = useSiteMetadata();

  const {
    title: postTitle,
    description: postDescription,
    keywords: postKeywords,
    image: postImage
  } = data.markdownRemark.frontmatter;

  const metaDescription = postDescription !== null ? postDescription : siteSubtitle;
  const metaKeywords = postKeywords !== null ? postKeywords : siteKeywords;
  const metaImage = postImage !== null ? postImage : siteAuthor.photo;
  const metaTwitter = {
    card: postImage !== null ? 'summary_large_image' : siteTwitter.card,
    site: siteTwitter.site,
    creator: siteTwitter.creator
  };

  return (
    <Layout title={`${postTitle} | ${siteTitle}`} description={metaDescription}
            keywords={metaKeywords} author={siteAuthor.name} isInnerPage={true}
            image={`${siteUrl}${metaImage}`} twitter={metaTwitter}>
      <Post post={data.markdownRemark} url={siteUrl} twitterHandle={metaTwitter.creator} />
    </Layout>
  );
};


export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        description
        keywords
        tags
        title
        image
      }
    }
  }
`;


export default PostTemplate;
