// @flow
import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  WhatsappShareButton,
  TelegramShareButton
} from 'react-share';
import Icon from '../../Icon';
import { getIcon } from '../../../utils';
import styles from './Share.module.scss';


type Props = {
  twitterHandle: string,
  url: string,
  title: string,
  tags: string[]
};

const Share = ({
  url,
  title,
  twitterHandle,
  tags
}: Props) => (
  <div className={styles['share']}>
    <h6>Share</h6>
    <ul className={styles['share__list']}>
      <li className={styles['share__list-item']}>
        <FacebookShareButton url={url}
                             className={`${styles['share__list-item-link']} ${styles['facebook']}`}>
          <span className={`${styles['share__list-item-link-icon']}`}>
            <Icon icon={getIcon('facebook')}/>
          </span>
          <span className={`${styles['share__list-item-link-text']}`}>Facebook</span>
        </FacebookShareButton>
      </li>
      <li className={styles['share__list-item']}>
        <TwitterShareButton url={url}
                            className={`${styles['share__list-item-link']} ${styles['twitter']}`}
                            via={twitterHandle.split('@').join('')}
                            title={title}
                            hashtags={tags}>
          <span className={`${styles['share__list-item-link-icon']}`}>
            <Icon icon={getIcon('twitter')}/>
          </span>
          <span className={`${styles['share__list-item-link-text']}`}>Twitter</span>
        </TwitterShareButton>
      </li>
      <li className={styles['share__list-item']}>
        <LinkedinShareButton url={url}
                             className={`${styles['share__list-item-link']} ${styles['linkedin']}`}
                             title={title}>
          <span className={`${styles['share__list-item-link-icon']}`}>
            <Icon icon={getIcon('linkedin')}/>
          </span>
          <span className={`${styles['share__list-item-link-text']}`}>LinkedIn</span>
        </LinkedinShareButton>
      </li>
      <li className={styles['share__list-item']}>
        <RedditShareButton url={url}
                           className={`${styles['share__list-item-link']} ${styles['reddit']}`}
                           title={title}>
          <span className={`${styles['share__list-item-link-icon']}`}>
            <Icon icon={getIcon('reddit')}/>
          </span>
          <span className={`${styles['share__list-item-link-text']}`}>Reddit</span>
        </RedditShareButton>
      </li>
      <li className={styles['share__list-item']}>
        <WhatsappShareButton url={url}
                             className={`${styles['share__list-item-link']} ${styles['whatsapp']}`}
                             title={title}>
          <span className={`${styles['share__list-item-link-icon']}`}>
            <Icon icon={getIcon('whatsapp')}/>
          </span>
          <span className={`${styles['share__list-item-link-text']}`}>WhatsApp</span>
        </WhatsappShareButton>
      </li>
      <li className={styles['share__list-item']}>
        <TelegramShareButton url={url}
                             className={`${styles['share__list-item-link']} ${styles['telegram']}`}
                             title={title}>
          <span className={`${styles['share__list-item-link-icon']}`}>
            <Icon icon={getIcon('telegram')}/>
          </span>
          <span className={`${styles['share__list-item-link-text']}`}>Telegram</span>
        </TelegramShareButton>
      </li>
    </ul>
  </div>
);

export default Share;
